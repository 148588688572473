import axios from 'axios'
import { useMutation } from 'react-query'

import type { DownloadPhoto } from '@facades/myPhotos'
import { getEndpoint } from '@utils/getEndpoint'

const downloadPhoto = async (data: DownloadPhoto) => {
  const response = await axios.post(
    `${getEndpoint()}/download_single_photo`,
    { ...data },
    {
      responseType: 'blob', // This is important for file downloads
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    }
  )

  const url = window.URL.createObjectURL(new Blob([response.data]))

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', data.downloadedFileName)
  document.body.appendChild(link)
  link.click()

  if (link.parentNode) {
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
}

export const useDownloadPhoto = () => {
  const mutation = useMutation(downloadPhoto)

  const { isLoading } = mutation

  return {
    ...mutation,
    isLoading,
  }
}
