import { Global } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'
import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from 'react-slick'

import {
  Img,
  MyPhotosContainer,
  SlickGlobal,
  SlickSlider,
  ThumbBtn,
  ThumbContainer,
  ThumbImg,
  MuiModal,
  MuiModalWrapper,
  MuiModalToolbar,
  MuiIconButton,
  Photos,
  ArrowContainer,
} from '@containers/myPhotos/myPhotos.styled'
// eslint-disable-next-line import/named
import { useDownloadPhoto } from '@hooks/useDownloadPhoto'
import { useGetMyPhotos } from '@hooks/useGetMyPhotos'
import { useGetQueryParam } from '@hooks/useGetQueryParam'
import { encodeBase64 } from '@utils/encodeBase64'
import { translationUtil } from '@utils/translationUtil'

export const MyPhotos = () => {
  const getId = useGetQueryParam('?getID=')
  const { data, isLoading: isDataLoading } = useGetMyPhotos(getId)
  const { mutate, isLoading } = useDownloadPhoto()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [slide, setSlider] = useState<number>(0)
  const [isOpen, setModalOpen] = useState<boolean>(false)
  const translations = translationUtil()
  let goTo: Slider | null = null

  const settings = {
    dots: false,
    infinite: true,
    speed: 1,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 10,
  }

  if (data?.myPhotos?.length) {
    const Carousel = ({ initialSlide = 0 }: { initialSlide?: number }) => (
      <>
        <SlickSlider
          ref={(slider) => (goTo = slider)}
          {...settings}
          initialSlide={initialSlide}
        >
          {data?.myPhotos?.map((item) => {
            const date = moment().format('DD-MM-YYYY')

            return (
              <Photos key={item.quest_media_id}>
                <Img
                  src={`${process.env.CLOUD_URL}${item.quest_media_file_url}`}
                />
                <MuiModalToolbar style={{ top: 'calc(100% - 36.5px)' }}>
                  <Button
                    component="label"
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      mutate({
                        filename: item.quest_media_file_name,
                        file: encodeBase64(String(item.quest_media_id)),
                        downloadedFileName: `${date} - ${
                          item.quest_media_id
                        }.${item.quest_media_file_name.split('.').pop()}`,
                        token: getId,
                      })
                    }
                    startIcon={<DownloadIcon fontSize="inherit" />}
                  >
                    {!isLoading
                      ? translations.downloadPhoto
                      : translations.downloadingInProgress}
                  </Button>
                </MuiModalToolbar>
              </Photos>
            )
          })}
        </SlickSlider>
        <ArrowContainer>
          <MuiIconButton
            aria-label="Previous"
            size="large"
            onClick={() => goTo?.slickPrev()}
          >
            <KeyboardArrowLeftIcon />
          </MuiIconButton>
          <MuiIconButton
            aria-label="Next"
            size="large"
            onClick={() => goTo?.slickNext()}
          >
            <KeyboardArrowRightIcon />
          </MuiIconButton>
        </ArrowContainer>
      </>
    )

    return (
      <>
        <Global styles={SlickGlobal} />
        <MyPhotosContainer>
          <Carousel initialSlide={slide} />
          <ThumbContainer>
            {data?.myPhotos?.map((item, index) => {
              return (
                <ThumbBtn
                  key={item.quest_media_id}
                  onClick={() => {
                    goTo?.slickGoTo(index)
                    window.scroll({ top: 0 })
                  }}
                >
                  <ThumbImg
                    src={`${process.env.CLOUD_URL}${item.quest_media_file_thumb_url}`}
                  />
                </ThumbBtn>
              )
            })}
          </ThumbContainer>
        </MyPhotosContainer>
        <MuiModal open={isOpen}>
          <MuiModalWrapper>
            <MuiModalToolbar>
              <MuiIconButton
                aria-label="close"
                size="large"
                onClick={() => setModalOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </MuiIconButton>
            </MuiModalToolbar>
            <Carousel initialSlide={slide} />
          </MuiModalWrapper>
        </MuiModal>
      </>
    )
  }

  if (!isDataLoading && !data?.myPhotos?.length) {
    return (
      <Photos noData>
        <span>{translations.noData}</span>
      </Photos>
    )
  }

  return (
    <MyPhotosContainer withLoader>
      <CircularProgress color="secondary" />
    </MyPhotosContainer>
  )
}
