import { css } from '@emotion/react'

export const GlobalStyle = css`
  .microUIGlobalCss button[type='submit']:not(.w-btn),
  .microUIGlobalCss input[type='submit']:not(.w-btn),
  .microUIGlobalCss .us-nav-style_1 > *,
  .microUIGlobalCss .navstyle_1 > .owl-nav button,
  .microUIGlobalCss .us-btn-style_1 {
    color: #fff !important;
  }
`
