// eslint-disable-next-line import/no-unresolved
import cz from '@translations/cz.json'
// eslint-disable-next-line import/no-unresolved
import en from '@translations/en.json'
export const translationUtil = () => {
  const object = {
    cz,
    cs: cz,
    en,
  }

  if (['cz', 'cs', 'en'].includes(window.wpmlLanguage)) {
    return object[window.wpmlLanguage as 'cz' | 'en'] as {
      [key: string]: string
    }
  }

  return object.en as {
    [key: string]: string
  }
}
