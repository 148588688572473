import axios, { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { MyPhotos } from '@facades/myPhotos'
import { QueriesEnum } from '@facades/queries'
import { getEndpoint } from '@utils/getEndpoint'

const fetchGetMyPhotos = async (reservationId: string): Promise<MyPhotos> => {
  const response = await axios.post<MyPhotos>(
    `${getEndpoint()}/get_reservation_photos`,
    {
      reservationId,
    }
  )
  return response.data
}

export const useGetMyPhotos = (
  reservationId: string,
  options?: UseQueryOptions<MyPhotos, AxiosError>
) => {
  return useQuery<MyPhotos, AxiosError>(
    [QueriesEnum.getMyPhotos, reservationId],
    () => fetchGetMyPhotos(reservationId),
    options
  )
}
